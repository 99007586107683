<form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
  <div class="page-title"><h1>{{i18nService.getMessage('reset.password.title')}}</h1></div>
  <div class="pb-3" *ngIf="!form.emailAddress else textWithEmailAddress">{{i18nService.getMessage('reset.password.text')}}</div>
  <ng-template #textWithEmailAddress>
    <div *ngIf="form.emailAddress">{{i18nService.getMessage('reset.password.text_with_emailaddress')}}</div>
  </ng-template>

  <!-- Username Field -->
  <div class="mb-3">
    <label class="form-label" for="username">{{i18nService.getMessage('login.reset_password.modal_panel.login_name')}}</label>
    <input id="username" type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.username.errors }"/>
    <div *ngIf="submitted && form.username.errors" class="invalid-feedback">
      <div *ngIf="form.username.errors.required">{{i18nService.getMessage('login.reset_password.modal_panel.login_name.required')}}</div>
    </div>
  </div>

  <!-- Loader -->
  <loader *ngIf="isInitialLoading$ | async"></loader>

  <!-- Email Address Field -->
  <div class="mb-3" *ngIf="form.emailAddress">
    <label class="form-label" for="email-address">{{i18nService.getMessage('login.reset_password.modal_panel.emailaddress')}}</label>
    <input id="email-address" type="email" formControlName="emailAddress" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.emailAddress.errors }"/>
    <div *ngIf="submitted && form.emailAddress.errors" class="invalid-feedback">
      <div *ngIf="form.emailAddress.errors.required">{{i18nService.getMessage('login.reset_password.modal_panel.emailaddress.required')}}</div>
      <div *ngIf="form.emailAddress.errors.email">{{i18nService.getMessage('login.reset_password.modal_panel.emailaddress.invalid')}}</div>
    </div>
  </div>

  <!-- Child Minder Checkbox -->
  <div class="form-check" *ngIf="form.childMinder">
    <input id="child-minder" type="checkbox" formControlName="childMinder" class="form-check-input"/>
    <label for="child-minder" class="form-check-label">{{i18nService.getMessage('login.input.login_as_childminder')}}</label>
  </div>

  <!-- Error Message -->
  <div class="mb-3 text-danger" *ngIf="!resetInProgress">
    <div *ngFor="let error of errors">{{i18nService.getMessage(error.messageCode, error.parameters[0])}}</div>
  </div>

  <!-- CAPTCHA Field -->
  <div class="mb-3" *ngIf="form.captchaCode">
    <label class="form-label">{{i18nService.getMessage('login.input.captcha')}}</label>
    <re-captcha formControlName="captchaCode" required></re-captcha>
  </div>

  <!-- Reset Password Button -->
  <div class="mb-3">
    <button id="reset-password-button" [disabled]="isRequestingResetPassword || initialLoading" class="btn btn-primary">
      <span *ngIf="isRequestingResetPassword" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <span *ngIf="!isRequestingResetPassword">{{i18nService.getMessage('login.reset_password.send.email')}}</span>
    </button>
  </div>
</form>

<!-- Back to Login Link -->
<a [routerLink]="loginLink" [queryParams]="{username: form.username.value}" >{{i18nService.getMessage('login.action.back_to_login')}}</a>

