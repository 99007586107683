import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'auth-ui-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})

export class AppComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {

  }
}

