import {ThemingService} from 'jrz-auth-api';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {i18nService} from 'jrz-common';
import {appRoutesNames} from '../app/app.routes.name';

@Component({
  selector: 'auth-ui-error',
  templateUrl: 'error.component.html',
  styleUrls: ['error.component.scss'],
  standalone: false
})
export class ErrorComponent implements OnInit {
  public readonly loginPageLink = '../' + appRoutesNames.LOGIN;
  public loadedTheme : string = "";

  constructor(
    public i18nService: i18nService,
    private router: Router,
    private themingService: ThemingService,
  ) {}

  ngOnInit() {
    this.loadedTheme = this.themingService.getThemeName();
  }

  onSubmit() {
    this.router.navigate(['/auth/login']);
  }

}
