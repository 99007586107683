<ng-container>
  <div class="logo"></div>

  <ng-container *ngIf="isParent">
    <!-- Success Message for Opt-out -->
    <div class="mb-3" *ngIf="!optOut && optOutInSuccess">
      <div>{{i18nService.getMessage('newsletter.opt_out.successful')}}</div>
    </div>

    <!-- Success Message for Opt-in -->
    <div class="mb-3" *ngIf="optOut && optOutInSuccess">
      <div>{{i18nService.getMessage('newsletter.opt_in.successful')}}</div>
    </div>

    <!-- Opt-out text when not successful -->
    <div class="mb-3" *ngIf="!optOut && !optOutInSuccess">
      {{i18nService.getMessage('newsletter.opt_out.text')}}
    </div>

    <!-- Opt-in text when not successful -->
    <div class="mb-3" *ngIf="optOut && !optOutInSuccess">
      {{i18nService.getMessage('newsletter.opt_in.text')}}
    </div>

    <!-- Username Information -->
    <div class="mb-3">
      {{i18nService.getMessage('newsletter.opt_in_out.username', username)}}
    </div>

    <!-- Opt-in/Opt-out Button -->
    <div class="mb-3" *ngIf="!optOutInSuccess">
      <button (click)="optInOut()" id="login-button" class="btn btn-primary">
        <span *ngIf="optOut">{{i18nService.getMessage('newsletter.action.opt_in')}}</span>
        <span *ngIf="!optOut">{{i18nService.getMessage('newsletter.action.opt_out')}}</span>
      </button>
    </div>
  </ng-container>

  <!-- Message for Non-Parents -->
  <ng-container *ngIf="!isParent">
    <div class="mb-3">
      <div>{{i18nService.getMessage('newsletter.opt_in_out.not_a_parent')}}</div>
    </div>
  </ng-container>
</ng-container>
