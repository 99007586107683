import {Component} from '@angular/core';
import {GenericDataService} from "../../../jrz-auth-api/src/lib/generic-data.service";
import {ThemingService} from "../../../jrz-auth-api/src/lib/theming/theming.service";
import {i18nService} from 'jrz-common';
import * as Bowser from "bowser";

@Component({
    selector: 'auth-main',
    templateUrl: 'main.component.html',
    styleUrls: ['main.component.scss'],
    standalone: false
})
export class MainComponent {
    public rightBrowserButVersionNotSupported = false;
    public logoUrl: string;
    public currentYear: string;

    constructor(public i18nService: i18nService,
                public themingService: ThemingService,
                public genericDataService: GenericDataService) {}

    ngOnInit(): void {
        const browser = Bowser.getParser(window.navigator.userAgent);
        this.rightBrowserButVersionNotSupported = browser.satisfies({
            edge: "<15",
            firefox: "<31",
            chrome: "<49",
            safari: "<9",
        });

        this.logoUrl = this.themingService.logoFileName;


        this.currentYear = new Date().getFullYear().toString();
        this.genericDataService.getCurrentYearFromServer().subscribe(data => {
            if(data) {
                this.currentYear = data.currentYear;
            }
        });
    }
}
