import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {catchError, distinctUntilChanged, finalize, first, last, mapTo, share, startWith, takeUntil} from 'rxjs/operators';
import {CredentialsService, ResetPasswordRequest, ChildMinderService} from 'jrz-auth-api';
import {ActivatedRoute, Router} from '@angular/router';
import {appRoutesNames} from '../../app/app.routes.name';
import {i18nService} from 'jrz-common';
import {combineLatest, forkJoin, merge, Observable, of, timer} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CaptchaService } from 'projects/jrz-auth-api/src/lib/captcha/captcha.service';

@Component({
    selector: 'auth-reset-password',
    templateUrl: 'reset-password.component.html',
    styleUrls: ['reset-password.component.scss'],
    standalone: false
})
export class ResetPasswordComponent implements OnInit {
    public isInitialLoading$: Observable<boolean>;

    public readonly loginLink = '../' + appRoutesNames.LOGIN;
    private readonly startLoadingTime = 1000;

    resetPasswordForm: FormGroup;
    initialLoading = false;
    submitted = false;
    errors = [];
    public resetInProgress: boolean = false;

    public isRequestingResetPassword: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        public i18nService: i18nService,
        private credentialsService: CredentialsService,
        private childMinderService: ChildMinderService,
        private router: Router,
        private route: ActivatedRoute,
        private captchaService: CaptchaService,
    ) {}

    ngOnInit() {
        this.initialLoading = true;
        this.resetPasswordForm = this.formBuilder.group({
          username: [this.credentialsService.currentUsername, Validators.required],
        });

        this.addOptionalFields();
    }

    private addCaptcha(blockedUntil: string) {
        this.resetPasswordForm.addControl('captchaCode', new FormControl());
        this.errors = [{
            messageCode: 'reset.password.blocked',
            parameters: [blockedUntil]
        }];
    }

    private addOptionalFields() {
        const result$ = forkJoin([this.credentialsService.isEmailRequired(), this.childMinderService.hasChildMinderModule(),
            this.captchaService.showCaptchaForReset().pipe(catchError(err => of(err)))])
            .pipe(share())

        this.isInitialLoading$ = merge (
            // ON in 1s
            timer(this.startLoadingTime).pipe(takeUntil(result$), mapTo(true)),

            // OFF once we receive a result
            result$.pipe(mapTo(false))
        ).pipe(
            startWith(false),
            distinctUntilChanged(),
            share()
        )

        // only when result and isInitialLoading emitted, subscribe.
        combineLatest([result$, this.isInitialLoading$.pipe(last())])
            .pipe(
                first(),
                finalize(() => this.initialLoading = false))
            .subscribe(([[emailRequired, hasChildMinderModule, captcha]]) => {
                if (emailRequired) {
                    this.resetPasswordForm.addControl('emailAddress', new FormControl('', [Validators.required, Validators.email]));
                }

                if (hasChildMinderModule) {
                    this.resetPasswordForm.addControl('childMinder', new FormControl(false));
                }

                if (captcha && captcha.blockedUntil) {
                    this.addCaptcha(captcha.blockedUntil);
                }
            });
    }

    get form() {
        return this.resetPasswordForm.controls;
    }

    onSubmit() {
        this.submitted = true;

        if (this.resetPasswordForm.invalid) {
            return;
        }

        this.resetInProgress = true;
        this.isRequestingResetPassword = true;
        const newPasswordRequest: ResetPasswordRequest = Object.assign({}, this.resetPasswordForm.value);
        this.credentialsService.resetPassword(newPasswordRequest)
            .pipe(
                first(),
                finalize(() => this.isRequestingResetPassword = false))
            .subscribe(result => {
                if (result) {
                    this.router.navigate([this.loginLink],
                        {queryParams: {resetPasswordSuccess: true, }, state: {username : this.form.username.value}},);
                }
            }, errorResponse => {
                if (errorResponse instanceof HttpErrorResponse) {
                    const captchaError = errorResponse.error?.messages?.find((error: any) => error.messageCode === 'reset.password.blocked');
                    if (captchaError) {
                        this.addCaptcha(captchaError.parameters[0])
                    } else {
                        this.resetPasswordForm.removeControl('captchaCode');
                        this.errors = errorResponse.error?.messages;
                    }
                }
                this.resetInProgress = false;
            });
    }
}
